import { cva } from 'class-variance-authority';

export const cvaRoot = cva([
  'AddToCartNotifyStyles-cvaRoot',
  'flex items-center gap-1.6',
  'fixed left-1/2 -translate-x-1/2 md:bottom-3.2 bottom-7 z-[10]',
  'w-auto',
  'p-1.6 sm:p-2.4',
  'text-cWhite text-bodyM whitespace-nowrap',
  'bg-cBlack',
  'rounded-[1.6rem]',
]);

export const cvaIcon = cva([
  'AddToCartNotifyStyles-cvaIcon',
  'w-1.6 h-1.6 min-w-[1.6rem]',
]);
