'use client';
import { Slider } from '@/features/slider';
import { IProduct } from '@/shared/api/getMainPage/types';
import { Container } from '@/shared/ui/Container';
import { Link } from '@/shared/ui/Link';
import { Title } from '@/shared/ui/Title';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { ProductPreviewCard } from '@/widgets/product-preview-card';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import { FC, useRef } from 'react';
import ArrowRightIcon from '~/icons/arrow-right.svg';

interface Props {
  className?: string;
  title?: string;
  descr?: string;
  href?: string;
  variant: 'grid' | 'slider';
  products: IProduct[];
}

export const CatalogCategoryPreview: FC<
  Props
> = ({
  className,
  title,
  descr,
  href,
  variant,
  products,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  return (
    <Container
      ref={ref}
      className={twMergeExt(cvaRoot(), className)}
      variant={'full'}
    >
      <Container
        className={cvaTopContainer()}
        variant={'small'}
      >
        <div className={cvaTopLeftContainer()}>
          {title ? (
            <Title
              as={'h2'}
              className={cvaHeading()}
              trigger={inView}
            >
              {title}
            </Title>
          ) : null}
          {descr ? (
            <Title
              as={'div'}
              className={cvaDescr()}
              delay={0.2}
              trigger={inView}
            >
              {descr}
            </Title>
          ) : null}
        </div>
      </Container>

      {variant === 'grid' ? (
        <>
          <div className={cvaProductListGrid()}>
            {products.map((product, index) => {
              return (
                <ProductPreviewCard
                  {...product}
                  href={`${product.code}`}
                  key={product.id}
                  className={'bg-cWhite'}
                  variant={
                    index === 0 || index === 5
                      ? 'large'
                      : 'default'
                  }
                  style={{
                    transition:
                      'opacity 0.3s, transform 0.3s',
                    transitionDelay: `${0.3 + index * 0.05}s`,
                    opacity: inView ? 1 : 0,
                    transform: inView
                      ? 'translateY(0)'
                      : 'translateY(2.4rem)',
                  }}
                />
              );
            })}
          </div>

          <Slider
            className={'sm:hidden'}
            slideClassName={'w-[23.2rem] h-auto'}
          >
            {products.map((product, index) => (
              <ProductPreviewCard
                {...product}
                href={`${product.code}`}
                key={product.id}
                className={'bg-cWhite'}
                style={{
                  transition:
                    'opacity 0.3s, transform 0.3s',
                  transitionDelay: `${0.3 + index * 0.05}s`,
                  opacity: inView ? 1 : 0,
                  transform: inView
                    ? 'translateY(0)'
                    : 'translateY(2.4rem)',
                }}
              />
            ))}
          </Slider>
        </>
      ) : null}

      {variant === 'slider' ? (
        <Slider
          slideClassName={
            'w-[23.2rem] sm:w-full h-auto'
          }
        >
          {products.map((product, index) => (
            <ProductPreviewCard
              {...product}
              href={`${product.code}`}
              key={product.id}
              className={'bg-cWhite h-full'}
              style={{
                transition:
                  'opacity 0.3s, transform 0.3s',
                transitionDelay: `${0.3 + index * 0.05}s`,
                opacity: inView ? 1 : 0,
                transform: inView
                  ? 'translateY(0)'
                  : 'translateY(2.4rem)',
              }}
            />
          ))}
        </Slider>
      ) : null}

      {href ? (
        <div className={'flex justify-center'}>
          <Link
            className={cvaLink()}
            color={'outlinedOnColor'}
            size={'big'}
            hoverEffect={'highlight'}
            href={href}
          >
            <div
              className={
                'flex justify-center items-center gap-[0.45rem] pl-0.9'
              }
            >
              <span>В каталог</span>
              <ArrowRightIcon
                className={
                  'w-1.6 h-1.6 sm:w-1.8 sm:h-1.8 mt-0.5'
                }
              />
            </div>
          </Link>
        </div>
      ) : null}
    </Container>
  );
};

const cvaRoot = cva([
  'CatalogCategoryPreview-cvaRoot',
  'relative',
  'overflow-hidden',
  'py-4 rounded-[4rem] sm:rounded-[6.4rem] sm:pt-5.6 sm:pb-7.2',
]);

const cvaTopContainer = cva([
  'CatalogCategoryPreview-cvaTopContainer',
  'flex justify-between items-center',
]);

const cvaTopLeftContainer = cva([
  'CatalogCategoryPreview-cvaTopLeftContainer',
  'flex flex-wrap gap-x-3.2 gap-y-1.2 flex-col lg:flex-row lg:items-center',
  'xs:w-4 md:w-[auto]',
]);

const cvaHeading = cva([
  'CatalogCategoryPreview-cvaHeading',
  'font-secondary text-h2',
]);

const cvaDescr = cva([
  'CatalogCategoryPreview-cvaDescr',
  'font-semibold text-[1.6rem] leading-[148%] underline decoration-cBlack32 underline-offset-4',
]);

const cvaLink = cva([
  'CatalogCategoryPreview-cvaLink',
  'sm:mt-4.8',
  'text-bodyLSemibold',
]);

const cvaProductListGrid = cva([
  'CatalogCategoryPreview-cvaProductListGrid',
  'hidden grid-cols-2 gap-1.2 sm:grid md:grid-cols-3 lg:grid-cols-4',
  'mt-4.8 px-2.4',
]);
