import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import { FC } from 'react';

interface Props {
  variant?: VariantProps<
    typeof cvaRoot
  >['mobileSize'];
  className?: string;
  title: string;
  icon?: string;
  color: string;
}

export const Tag: FC<Props> = ({
  className,
  title,
  icon,
  color,
  variant = 'default',
}) => {
  return (
    <div
      className={twMergeExt(
        cvaRoot({ mobileSize: variant }),
        className
      )}
      style={{ backgroundColor: color }}
    >
      {icon ? (
        <LazyImage
          wrapClassName={cvaIconWrap({
            mobileSize: variant,
          })}
          className={'object-contain'}
          src={icon}
          alt={title ?? ''}
          fill
        />
      ) : null}

      <div className={cvaTitle()}>{title}</div>
    </div>
  );
};

const cvaRoot = cva(
  [
    'Tag-cvaRoot',
    'inline-flex items-center',
    'sm:rounded-[0.8rem]',
    'sm:gap-[0.4rem]',
    'sm:p-[0.4rem_0.6rem_0.4rem_0.4rem]',
  ],
  {
    variants: {
      mobileSize: {
        default: [
          'rounded-[0.6rem]',
          'gap-[0.2rem]',
          'p-[0.35rem_0.5rem_0.45rem_0.3rem]',
        ],
        catalog: [
          'rounded-[0.4rem]',
          'gap-[0.3rem]',
          'p-[0.15rem_0.4rem_0.15rem_0.3rem]',
        ],
      },
    },
    defaultVariants: {
      mobileSize: 'default',
    },
  }
);
const cvaIconWrap = cva(
  [
    'Tag-cvaIconWrap',
    'relative',
    'sm:w-1.6 sm:h-1.6',
  ],
  {
    variants: {
      mobileSize: {
        default: 'w-1.2 h-1.2',
        catalog: 'w-0.9 h-0.9',
      },
    },
    defaultVariants: {
      mobileSize: 'default',
    },
  }
);

const cvaTitle = cva([
  'Tag-cvaTitle',
  'text-labelM text-cWhite uppercase',
]);
