'use client';
import { SliderNavigationButton } from '@/shared/ui/SliderNavigationButton';
import { SliderProgress } from '@/shared/ui/SliderProgress';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import {
  Children,
  FC,
  ReactNode,
  useState,
} from 'react';
import {
  Autoplay,
  Navigation,
} from 'swiper/modules';
import {
  Swiper,
  SwiperSlide,
} from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';

interface Props {
  className?: string;
  sliderClassName?: string;
  slideClassName?: string;
  children?: ReactNode;
  direction?: 'horizontal' | 'vertical';
}

export const Slider: FC<Props> = ({
  className,
  sliderClassName,
  slideClassName,
  children,
}) => {
  const [swiper, setSwiper] =
    useState<SwiperType | null>(null);

  return (
    <div
      className={twMergeExt(cvaRoot(), className)}
    >
      <div className={cvaControls()}>
        <div className={cvaNavigations()}>
          <SliderProgress
            className="justify-center self-center md:mr-3"
            swiper={swiper}
          />
          <SliderNavigationButton
            className={cvaNavigationButton()}
            direction={'prev'}
            swiper={swiper}
            color={'outlinedOnColor'}
          />
          <SliderNavigationButton
            className={cvaNavigationButton()}
            direction={'next'}
            swiper={swiper}
            color={'outlinedOnColor'}
          />
        </div>
      </div>

      <div>
        <Swiper
          className={sliderClassName}
          modules={[Navigation, Autoplay]}
          speed={1000}
          autoplay={{
            delay: 10000,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 'auto',
              slidesPerGroup: 1,
              spaceBetween: 8,
              slidesOffsetBefore: 24,
              slidesOffsetAfter: 24,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 12,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 12,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            1440: {
              slidesPerView: 4,
              slidesPerGroup: 4,
              spaceBetween: 12,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          }}
          onSwiper={setSwiper}
        >
          {Children.map(children, (child) => (
            <SwiperSlide
              className={twMergeExt(
                cvaSlide(),
                slideClassName
              )}
            >
              {child}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

const cvaRoot = cva([
  'Slider-cvaRoot',
  'flex flex-col-reverse sm:block',
  'mt-2.4 sm:mx-2.4 sm:mt-4.8',
]);

const cvaControls = cva([
  'Slider-cvaControls',
  'static top-5.6 right-5.6 sm:absolute',
  'flex flex-col items-center gap-2.4 sm:gap-3.2 sm:flex-row',
  'mt-2.4 sm:mt-0',
]);

const cvaNavigations = cva([
  'Slider-cvaNavigations',
  'flex xs:gap-0.8 mb-2.4 sm:mb-3.2 md:mb-0 justify-center',
]);

const cvaNavigationButton = cva([
  'Slider-cvaNavigationButton',
  'hidden sm:flex',
  'bg-transparent translate-y-0',
]);

const cvaSlide = cva(['Slider-cvaSlide', 'flex']);
