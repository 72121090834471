import {
  cvaButton,
  cvaButtonWrapper,
} from '@/shared/styles/button.styles';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import NextLink from 'next/link';
import {
  ComponentProps,
  forwardRef,
  ReactNode,
} from 'react';

import { useInView } from 'react-intersection-observer';
import { AnimatedTranslateWrapper } from './AnimatedTranslateWrapper';

interface Props
  extends Omit<
    ComponentProps<typeof NextLink>,
    'color'
  > {
  wrapperClassName?: string;
  translateWrapperClassName?: string;
  contentWrapperClassName?: string;
  rootWrapperClassName?: string;
  animateWrapperClassName?: string;
  color?: VariantProps<typeof cvaButton>['color'];
  size?: VariantProps<typeof cvaButton>['size'];
  hoverEffect?: VariantProps<
    typeof cvaButton
  >['hoverEffect'];
  badgeSlot?: ReactNode;
  disableInViewAnimation?: boolean;
}

export const Link = forwardRef<
  HTMLAnchorElement,
  Props
>(
  (
    {
      className,
      wrapperClassName,
      translateWrapperClassName,
      contentWrapperClassName,
      rootWrapperClassName,
      animateWrapperClassName,
      children,
      badgeSlot,
      color,
      size,
      hoverEffect,
      disableInViewAnimation = false,
      ...props
    },
    ref
  ) => {
    const { ref: buttonWrapperRef, inView } =
      useInView({
        triggerOnce: true,
        threshold: 1.0,
      });

    const hasInViewAnimation = () => {
      return (
        (hoverEffect === 'highlight' ||
          hoverEffect === 'roundLight') &&
        !disableInViewAnimation
      );
    };
    return (
      <div
        className={twMergeExt(
          cvaButtonWrapper(),
          rootWrapperClassName
        )}
        ref={buttonWrapperRef}
      >
        <AnimatedTranslateWrapper
          disableInViewAnimation={
            !hasInViewAnimation()
          }
          className={animateWrapperClassName}
          initial={{
            y: hasInViewAnimation() ? '3rem' : 0,
            opacity: hasInViewAnimation() ? 0 : 1,
          }}
          transition={{
            delay: hasInViewAnimation() ? 0.2 : 0,
            ease: 'easeInOut',
          }}
          trigger={inView}
        >
          <NextLink
            className={twMergeExt(
              cvaRoot(),
              cvaButton({
                hoverEffect,
                color,
                size,
                highlightByColor:
                  hoverEffect === 'highlight'
                    ? color
                    : null,
              }),
              size === 'big' &&
                [
                  'outlined',
                  'outlined32',
                ].includes(color ?? '')
                ? cvaButton({
                    hoverEffect:
                      hoverEffect || 'highlight',
                    highlightByColor: color,
                  })
                : null,
              className
            )}
            {...props}
            ref={ref}
          >
            <div
              className={twMergeExt(
                'overflow-hidden z-10',
                wrapperClassName
              )}
            >
              <div
                className={twMergeExt(
                  cvaTranslateWrapper(),
                  translateWrapperClassName
                )}
              >
                <div
                  className={
                    contentWrapperClassName
                  }
                >
                  {children}
                </div>

                {hoverEffect === 'translate' ? (
                  <div
                    className={twMergeExt(
                      cvaHiddenContentWrapper(),
                      contentWrapperClassName
                    )}
                  >
                    {children}
                  </div>
                ) : null}
              </div>
            </div>

            {badgeSlot}
          </NextLink>
        </AnimatedTranslateWrapper>
      </div>
    );
  }
);

Link.displayName = 'Link';

const cvaRoot = cva([
  'Link-cvaRoot',
  'inline-block',
  'align-text-bottom',
]);

const cvaTranslateWrapper = cva([
  'Link-cvaTranslateWrapper',
  'relative',
  'transition-transform duration-300 ease-out',
  'group-disabled/translateEffect:-translate-y-0',
  'group-hover/translateEffect:-translate-y-full',
  'group-active/translateEffect:-translate-y-full',
]);

const cvaHiddenContentWrapper = cva([
  'Link-cvaHiddenContentWrapper',
  'absolute top-0 left-0',
  'translate-y-full',
]);
