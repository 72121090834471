'use client';
import { ProductPreviewCardImages } from '@/entities/product/ui/ProductPreviewCardImages';
import { IProduct } from '@/shared/api/getMainPage/types';
import { AnimatedLine } from '@/shared/ui/AnimatedLine';
import { Link } from '@/shared/ui/Link';
import { Tag } from '@/shared/ui/Tag';
import { formatCurrency } from '@/shared/utils/formatters';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { cva } from 'class-variance-authority';
import { useInView } from 'framer-motion';
import {
  CSSProperties,
  forwardRef,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import DiscountIcon from '~/icons/discount.svg';
import { productTagsDataByTitleMock } from '../../../../data/productTagsDataMock';

interface Props extends IProduct {
  className?: string;
  topContainerClassName?: string;
  imageWrapperClassName?: string;
  tagsClassName?: string;
  infoContainerClassName?: string;
  href: string;
  variant?: 'default' | 'large';
  mobileVariant?: 'default' | 'catalog';
  style?: CSSProperties;
  addToFavoriteButtonSlot?: ReactNode;
  addToCartButtonSlot?: ReactNode;
  onClick?: () => void;
  isBgGray?: boolean;
  isSale?: boolean;
  isUnavailable?: boolean;
  isLoading?: boolean;
  preview_picture?: string;
  onSearchPopup?: boolean;
}

export const ProductPreviewCardBase = forwardRef<
  HTMLAnchorElement,
  Props
>(
  (
    {
      className,
      topContainerClassName,
      imageWrapperClassName,
      tagsClassName,
      infoContainerClassName,
      label,
      href,
      images,
      brand,
      name,
      volume,
      price,
      discount_price,
      variant = 'default',
      mobileVariant = 'default',
      style,
      addToFavoriteButtonSlot,
      addToCartButtonSlot,
      onClick,
      isBgGray,
      isUnavailable,
      detail_picture, // isSale
      preview_picture,
      isLoading = false,
      onSearchPopup = false,
    },
    ref
  ) => {
    const rootRef =
      useRef<HTMLAnchorElement>(null);
    const inView = useInView(rootRef, {
      once: true,
    });

    const variantTags = useMemo(
      () =>
        onSearchPopup ? 'catalog' : mobileVariant,
      [mobileVariant, onSearchPopup]
    );

    return (
      <Link
        ref={mergeRefs([ref, rootRef])}
        onClick={onClick}
        className={twMergeExt(
          cvaRoot({
            mobileSize: mobileVariant,
            isLoading: isLoading,
            onSearchPopup: onSearchPopup,
          }),
          className
        )}
        rootWrapperClassName={cvaRootWrapper({
          size: variant,
        })}
        animateWrapperClassName="w-full"
        wrapperClassName={
          'flex flex-col flex-1 overflow-visible'
        }
        translateWrapperClassName={
          'flex flex-col flex-1'
        }
        contentWrapperClassName={
          'flex flex-col flex-1'
        }
        href={`/product/${href}`}
        style={style}
      >
        <div
          className={twMergeExt(
            cvaTopContainer(),
            topContainerClassName
          )}
        >
          <div
            className={twMergeExt(
              cvaTags({
                mobileSize: variantTags,
              }),
              tagsClassName
            )}
          >
            {label?.map((i, index) => (
              <Tag
                key={index}
                variant={variantTags}
                {...productTagsDataByTitleMock[
                  i.id
                ]}
              />
            ))}
          </div>

          {addToFavoriteButtonSlot ? (
            <div
              className={cvaAddToFavoriteButtonWrapper()}
              onClick={(e) => {
                e.nativeEvent.stopImmediatePropagation();
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              {addToFavoriteButtonSlot}
            </div>
          ) : null}
        </div>
        {!isLoading ? (
          <ProductPreviewCardImages
            imageWrapperClassName={
              imageWrapperClassName
            }
            isUnavailable={isUnavailable}
            size={variant}
            mobileSize={mobileVariant}
            images={
              images && images.length > 0
                ? images
                : preview_picture
                  ? [preview_picture]
                  : detail_picture
                    ? [detail_picture]
                    : undefined
            }
            isBgGray={isBgGray}
            onSearchPopup={onSearchPopup}
            // isSale={isSale}
          />
        ) : (
          <div className="w-full h-[23rem]"></div>
        )}

        <div
          className={twMergeExt(
            'mt-auto',
            infoContainerClassName,
            isUnavailable ? 'opacity-[0.48]' : ''
          )}
        >
          {brand ? (
            <div
              className={cvaBrand({
                mobileSize: onSearchPopup
                  ? 'catalog'
                  : mobileVariant,
                size: onSearchPopup
                  ? 'default'
                  : variant,
                onSearchPopup: onSearchPopup,
              })}
            >
              {!isLoading ? brand : null}
            </div>
          ) : null}

          <div
            className={cvaNameContainer({
              mobileSize: mobileVariant,
              onSearchPopup: onSearchPopup,
            })}
          >
            <div
              className={cvaName({
                onSearchPopup: onSearchPopup,
              })}
            >
              {!isLoading ? name : null}
            </div>
            {name && volume ? '   ' : null}
            {volume && !isLoading ? (
              <div
                className={cvaVolume({
                  onSearchPopup: onSearchPopup,
                })}
              >
                {volume}&nbsp;мл
              </div>
            ) : null}
          </div>

          <div className="">
            <div
              className={cvaSeparator({
                mobileSize: onSearchPopup
                  ? 'catalog'
                  : mobileVariant,
              })}
            >
              <AnimatedLine
                className={''}
                direction={'toRight'}
                trigger={inView}
              />
            </div>

            <div className={cvaBottomContainer()}>
              {price && !isLoading ? (
                <div
                  className={cvaPriceContainer()}
                >
                  <div
                    className={cvaPrice({
                      mobileSize: mobileVariant,
                      onSearchPopup,
                    })}
                  >
                    {formatCurrency(
                      discount_price
                        ? discount_price
                        : price
                    )}
                  </div>
                  {discount_price ? (
                    <div
                      className={cvaDiscountPriceContainer()}
                    >
                      <div
                        className={cvaDiscountPrice()}
                      >
                        {formatCurrency(price)}
                      </div>
                      <div
                        className={cvaDiscountLabelContainer()}
                      >
                        <DiscountIcon
                          className={
                            'aspect-[46/25] w-4 sm:w-4.6 text-cExtraOrange'
                          }
                        />
                        <div
                          className={cvaDiscountPercent()}
                        >
                          -
                          {(
                            (1 -
                              discount_price /
                                price) *
                            100
                          ).toFixed(0)}
                          %
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {addToCartButtonSlot ? (
                <div
                  className={cvaAddToCartButtonWrapper(
                    { isUnavailable }
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {addToCartButtonSlot}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Link>
    );
  }
);

ProductPreviewCardBase.displayName =
  'ProductPreviewCardBase';

const cvaRoot = cva(
  [
    'ProductPreviewCardBase-cvaRoot group/ProductPreviewCard',
    'relative overflow-hidden',
    'h-full',
    'flex flex-col',
    'sm:p-2 md:p-3.2 rounded-[2rem] sm:rounded-[3.2rem]',
    'sm:text-bodyMSemibold',
  ],
  {
    variants: {
      mobileSize: {
        default: [
          'p-2 gap-2.4',
          'text-bodyMSemibold',
        ],
        catalog: ['p-1.6', 'text-bodyXS'],
      },
      isLoading: {
        true: ['!bg-cLightGrey'],
      },
      onSearchPopup: {
        true: ['!rounded-[16px] p-1.6 md:p-2.4'],
      },
    },
  }
);

const cvaRootWrapper = cva(
  [
    'ProductPreviewCardBase-cvaRootWrapper',
    'h-full',
    'gap-2.4',
  ],
  {
    variants: {
      size: {
        default: '',
        large: 'lg:col-span-2',
      },
    },
  }
);

const cvaTopContainer = cva([
  'ProductPreviewCardBase-cvaTopContainer',
  'absolute z-[1] top-0 left-0',
  'flex justify-between items-start',
  'w-full',
]);

const cvaTags = cva(
  [
    'ProductPreviewCardBase-cvaTags',
    'flex flex-wrap',
    'max-w-[12rem] sm:max-w-[16rem]',
    'sm:gap-0.4',
  ],
  {
    variants: {
      mobileSize: {
        default: 'gap-0.4',
        catalog: 'gap-0.2',
      },
    },
    defaultVariants: {
      mobileSize: 'default',
    },
  }
);

const cvaBrand = cva(
  [
    'ProductPreviewCardBase-cvaBrand',
    'text-cMediumGreen',
  ],
  {
    variants: {
      size: {
        // TODO: проверить по PP
        default: 'sm:mt-1.6',
        large: 'sm:mt-1.5',
      },
      mobileSize: {
        default: 'mt-1.2',
        catalog: 'mt-0.4',
      },
      onSearchPopup: {
        true: ['font-semibold text-[1.2rem]'],
      },
    },
  }
);

const cvaNameContainer = cva(
  [
    'ProductPreviewCardBase-cvaNameContainer',
    'text-overflow-ellipsis',
    'max-w-[31.3rem]',
  ],
  {
    variants: {
      mobileSize: {
        default: 'mt-0.8',
        catalog: 'mt-0.6',
      },
      onSearchPopup: {
        true: ['sm:mt-0 max-h-[8rem] h-[8rem]'],
        false: ['sm:mt-1.1'],
      },
    },
  }
);

const cvaName = cva(
  ['ProductPreviewCardBase-cvaName', 'inline'],
  {
    variants: {
      onSearchPopup: {
        true: [
          '!font-semibold !text-[1.2rem] leading-[10px]',
        ],
      },
    },
  }
);

const cvaVolume = cva(
  [
    'ProductPreviewCardBase-cvaVolume',
    'inline',
    'text-cBlack48',
  ],
  {
    variants: {
      onSearchPopup: {
        true: ['font-normal text-[1.2rem]'],
        false: ['text-bodyM'],
      },
    },
  }
);

const cvaBottomContainer = cva([
  'ProductPreviewCardBase-cvaBottomContainer',
  'flex justify-between items-center',
  'mt-0.4 sm:mt-0.8',
]);

const cvaPriceContainer = cva([
  'ProductPreviewCardBase-cvaPriceContainer',
  'pr-4',
  'flex items-center flex-wrap gap-x-0.8 sm:gap-x-1.2',
]);

const cvaPrice = cva(
  [
    'ProductPreviewCardBase-cvaPrice',
    'text-bodyMBold sm:text-bodyMBold',
  ],
  {
    variants: {
      mobileSize: {
        default: 'text-bodyMBold',
        catalog: 'text-bodyXSSemibold',
      },
      onSearchPopup: {
        true: '!text-bodyXSSemibold sm:!text-bodyXSSemibold',
      },
    },
  }
);

const cvaDiscountPriceContainer = cva([
  'ProductPreviewCardBase-cvaDiscountPriceContainer',
  'relative',
  'text-bodyM',
]);

const cvaDiscountPrice = cva([
  'ProductPreviewCardBase-cvaDiscountPrice',
  'relative',
  'text-cBlack48',
  'before:absolute before:top-1/2 before:-left-0.2',
  'before:w-[calc(100%+0.4rem)] before:h-0.1',
  'before:bg-cExtraOrange before:-translate-y-1/2 before:-rotate-[12deg]',
]);

const cvaDiscountLabelContainer = cva([
  'ProductPreviewCardBase-cvaDiscountLabelContainer',
  'absolute top-[calc(50%-0.2rem)] right-[calc(-100%-1.5rem)] -translate-y-1/2',
]);

const cvaDiscountPercent = cva([
  'ProductPreviewCardBase-cvaDiscountPercent',
  'absolute top-1/2 left-[calc(50%-0.2rem)] sm:left-[calc(50%-0.6rem)] -translate-x-1.2 -translate-y-1/2',
  'text-cWhite',
]);

const cvaAddToCartButtonWrapper = cva(
  [
    'ProductPreviewCardBase-cvaAddToCartButtonWrapper',
    'sm:invisible sm:opacity-0 sm:translate-y-1.6',
    'transition-all duration-400 ease-[cubic-bezier(0.67,0,0,1)]',
  ],
  {
    variants: {
      isUnavailable: {
        true: '',
        false: [
          'group-hover/ProductPreviewCard:visible group-hover/ProductPreviewCard:opacity-100 group-hover/ProductPreviewCard:translate-y-0',
          'group-active/ProductPreviewCard:visible group-active/ProductPreviewCard:opacity-100 group-active/ProductPreviewCard:translate-y-0',
        ],
      },
    },
    defaultVariants: {
      isUnavailable: false,
    },
  }
);

const cvaAddToFavoriteButtonWrapper = cva([
  'ProductPreviewCardBase-cvaAddToFavoriteButtonWrapper',
  'sm:invisible sm:opacity-0 sm:-translate-y-1.6',
  'transition-all duration-400 ease-[cubic-bezier(0.67,0,0,1)]',
  'group-hover/ProductPreviewCard:visible group-hover/ProductPreviewCard:opacity-100 group-hover/ProductPreviewCard:translate-y-0',
  'group-active/ProductPreviewCard:visible group-active/ProductPreviewCard:opacity-100 group-active/ProductPreviewCard:translate-y-0',
]);

const cvaSeparator = cva(
  [
    'ProductPreviewCardBase-cvaSeparator',
    'flex',
    'sm:mt-2.1',
  ],
  {
    variants: {
      mobileSize: {
        default: 'mt-1.5',
        catalog: 'mt-1.2',
      },
    },
  }
);
