'use client';
import { Button } from '@/shared/ui/Button';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import {
  forwardRef,
  useEffect,
  useState,
} from 'react';
import { useSwiper } from 'swiper/react';
import { Swiper } from 'swiper/types';
import ArrowRightIcon from '~/icons/arrow-right.svg';
import { cvaButton } from '../styles/button.styles';

interface Props {
  className?: string;
  direction: 'prev' | 'next';
  swiper?: Swiper | null;
  color?: VariantProps<typeof cvaButton>['color'];
  disableInViewAnimation?: boolean;
  rootWrapperClassName?: string;
  canBeDisabled?: boolean;
}

export const SliderNavigationButton = forwardRef<
  HTMLButtonElement,
  Props
>(
  (
    {
      className,
      direction,
      swiper,
      color,
      disableInViewAnimation,
      rootWrapperClassName,
      canBeDisabled = true,
    },
    ref
  ) => {
    const swiperContext = useSwiper() ?? swiper;

    const [isDisabled, setIsDisabled] = useState(
      canBeDisabled
    );

    const onClick = () => {
      direction === 'prev'
        ? swiperContext.slidePrev()
        : swiperContext.slideNext();
    };

    useEffect(() => {
      const handler = (swiper: Swiper) => {
        const disabled =
          direction === 'prev'
            ? Boolean(swiper?.isBeginning)
            : Boolean(swiper?.isEnd);
        if (canBeDisabled) {
          setIsDisabled(disabled);
        }
      };

      handler(swiperContext);
      swiperContext?.on(
        'reachBeginning',
        handler
      );
      swiperContext?.on('reachEnd', handler);
      swiperContext?.on(
        'realIndexChange',
        handler
      );

      return () => {
        swiperContext?.off(
          'reachBeginning',
          handler
        );
        swiperContext?.off('reachEnd', handler);
        swiperContext?.off(
          'realIndexChange',
          handler
        );
      };
    }, [swiperContext]);

    return (
      <Button
        ref={ref}
        className={twMergeExt(
          cvaRoot(),
          className
        )}
        rootWrapperClassName={
          rootWrapperClassName
        }
        color={color}
        hoverEffect={'highlight'}
        size={'small'}
        slot={
          direction === 'prev'
            ? 'container-start'
            : 'container-end'
        }
        onClick={onClick}
        disableInViewAnimation={
          disableInViewAnimation
        }
        disabled={isDisabled}
      >
        <ArrowRightIcon
          className={cvaArrowIcon({ direction })}
        />
      </Button>
    );
  }
);

SliderNavigationButton.displayName =
  'SliderButton';

const cvaRoot = cva([
  'SliderNavigationButton-cvaRoot',
  'relative z-[2]',
  'flex justify-center items-center',
  'w-4 h-4 sm:w-5.6 sm:h-5.6 min-h-0',
  'bg-cWhite',
  'transition-all duration-300',
  'disabled:opacity-[0.32] disabled:visible',
]);

const cvaArrowIcon = cva(
  [
    'SliderNavigationButton-cvaArrowIcon',
    'w-1.6 h-1.6',
  ],
  {
    variants: {
      direction: {
        prev: 'rotate-180',
        next: '',
      },
    },
  }
);
