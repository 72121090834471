'use client';
import { FC, useEffect } from 'react';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import {
  animate,
  motion,
  useMotionTemplate,
  useMotionValue,
} from 'framer-motion';
import { twMergeExt } from '@/shared/utils/twMergeExt';

interface Props {
  className?: string;
  trigger: boolean;
  direction?: VariantProps<
    typeof cvaRoot
  >['direction'];
}

export const AnimatedLine: FC<Props> = ({
  className,
  trigger,
  direction = 'toRight',
}) => {
  const backgroundSizeWidth = useMotionValue(
    ['toLeft', 'toRight'].includes(direction!)
      ? '0%'
      : '100%'
  );
  const backgroundSizeHeight = useMotionValue(
    ['toBottom', 'toTop'].includes(direction!)
      ? '0%'
      : '100%'
  );
  const backgroundSize = useMotionTemplate`${backgroundSizeWidth} ${backgroundSizeHeight}`;

  useEffect(() => {
    if (trigger) {
      const controls = animate(
        ['toLeft', 'toRight'].includes(direction!)
          ? backgroundSizeWidth
          : backgroundSizeHeight,
        '100%',
        { duration: 2, ease: 'easeInOut' }
      );

      return () => {
        controls.stop();
      };
    }
  }, [trigger]);

  return (
    <motion.div
      className={twMergeExt(
        cvaRoot({ direction }),
        className
      )}
      style={{ backgroundSize }}
    />
  );
};

const cvaRoot = cva(
  [
    'AnimatedLine-cvaRoot',
    'bg-no-repeat from-cBlack16 to-cBlack16',
  ],
  {
    variants: {
      direction: {
        toLeft:
          'w-full h-0.1 bg-gradient-to-l rotate-180',
        toRight: 'w-full h-0.1 bg-gradient-to-r',
        toBottom: 'w-0.1 h-full bg-gradient-to-b',
        toTop:
          'w-0.1 h-full bg-gradient-to-t rotate-180',
      },
    },
  }
);
