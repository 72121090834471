'use client';
import {
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

interface IPortalProps {
  children?: ReactNode;
  className?: string;
  containerId?: string;
}

export const Portal: FC<IPortalProps> = ({
  children,
  className,
  containerId,
}) => {
  const [mounted, setMounted] = useState(false);
  const [container, setContainer] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    // Этот эффект сработает только на клиенте, так как useEffect не выполняется на сервере
    setMounted(true);
    const el = document.createElement('div');
    className && el.classList.add(className);
    document.body.appendChild(el);
    setContainer(el);

    return () => {
      if (el && document.body.contains(el)) {
        document.body.removeChild(el);
      }
    };
  }, [className]);

  if (!mounted || !container) {
    // Пока компонент не смонтирован на клиенте, не рендерим портал
    return null;
  }

  return createPortal(
    children,
    containerId
      ? document.getElementById(containerId) ??
          container
      : container
  );
};
