'use client';
import { useOnClickOutside } from '@/shared/hooks/useOnClickOutside';
import { motion } from 'framer-motion';
import { FC, useEffect, useRef } from 'react';
import {
  cvaIcon,
  cvaRoot,
} from './AddToCartNotifyStyles';
import IconCheck from '/public/icons/check.svg';

interface Props {
  onClose?: () => void;
  disabled?: boolean;
}

const AddToCartNotify: FC<Props> = ({
  onClose,
  disabled,
}) => {
  const rootRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(rootRef, () => {
    onClose?.();
  });

  useEffect(() => {
    setTimeout(() => {
      onClose?.();
    }, 1200);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3,
        ease: 'easeInOut',
      }}
      className={cvaRoot()}
      ref={rootRef}
    >
      {disabled ? (
        'Больше нет'
      ) : (
        <>
          <IconCheck className={cvaIcon()} />{' '}
          Товар добавлен в корзину
        </>
      )}
    </motion.div>
  );
};

export default AddToCartNotify;
