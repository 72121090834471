import { create } from 'zustand';

interface HeaderStoreState {
  isVisible: boolean;
  allowChangeVisibility: boolean;
  height: number;
  showCityDefault: boolean;
  showChooseCity: boolean;
  isRounded: boolean;
  showLeftArrow: boolean;
}

interface HeaderStoreActions {
  setIsVisible: (isVisible: boolean) => void;
  setAllowChangeVisibility: (
    allowChangeVisibility: boolean
  ) => void;
  setHeight: (height: number) => void;
  setShowCityDefault: (value: boolean) => void;
  getShowCityDefaultFromLocalStorage: () => void;
  setShowChooseCity: (value: boolean) => void;
  setRounded: (value: boolean) => void;
  setShowLeftArrow: (value: boolean) => void;
}

export const useHeaderStore = create<
  HeaderStoreState & HeaderStoreActions
>((set) => ({
  isVisible: true,
  allowChangeVisibility: true,
  height: 0,
  showCityDefault: false,
  showChooseCity: false,
  isRounded: true,
  showLeftArrow: false,
  setAllowChangeVisibility: (
    allowChangeVisibility
  ) => {
    set({ allowChangeVisibility });
  },
  setIsVisible: (isVisible) => {
    set({ isVisible });
  },
  setShowLeftArrow: (value) => {
    set(() => {
      return {
        showLeftArrow: value,
      };
    });
  },
  setRounded: (value) => {
    set(() => {
      return {
        isRounded: value,
      };
    });
  },
  setShowChooseCity: (value) => {
    set(() => {
      return {
        showChooseCity: value,
      };
    });
  },
  getShowCityDefaultFromLocalStorage: () => {
    const showCityDefault = localStorage.getItem(
      'showCityDefault'
    );
    if (showCityDefault) {
      set(() => {
        return {
          showCityDefault: false,
        };
      });
    } else {
      set(() => {
        return {
          showCityDefault: true,
        };
      });
    }
  },
  setShowCityDefault: (value) => {
    set(() => {
      return {
        showCityDefault: value,
      };
    });
  },
  setHeight: (height) => {
    set(() => {
      return {
        height,
      };
    });
  },
}));
