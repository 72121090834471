'use client';
import { LazyImage } from '@/shared/ui/LazyImage';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import {
  cva,
  VariantProps,
} from 'class-variance-authority';
import {
  AnimatePresence,
  motion,
} from 'framer-motion';
import { FC, useState } from 'react';
import EmptyIcon from '~/icons/product_empty.svg';
import EmptyIconLight from '~/icons/product_empty_light.svg';
import IconNotAllowed from '/public/icons/notallowed.svg';

interface Props {
  className?: string;
  imageWrapperClassName?: string;
  size: VariantProps<
    typeof cvaImageWrapper
  >['size'];
  mobileSize: VariantProps<
    typeof cvaImageWrapper
  >['mobileSize'];
  images?: string[];
  isBgGray?: boolean;
  isSale?: boolean;
  isUnavailable?: boolean;
  onSearchPopup?: boolean;
}

export const ProductPreviewCardImages: FC<
  Props
> = ({
  className,
  imageWrapperClassName,
  size,
  mobileSize,
  images = [],
  isBgGray,
  isSale,
  isUnavailable,
  onSearchPopup = false,
}) => {
  const [activeIndex, setActiveIndex] =
    useState(0);
  const [isImageLoaded, setIsImageLoaded] =
    useState(false);

  return (
    <div
      className={twMergeExt(
        cvaRoot({
          size,
          mobileSize,
          isUnavailable,
          onSearchPopup,
        }),
        className
      )}
    >
      {isUnavailable ? (
        <div
          className={cvaUnavailable({ isBgGray })}
        >
          <IconNotAllowed className="w-1.6 h-1.6" />
          Нет в наличии
        </div>
      ) : null}
      <div
        className={twMergeExt(
          cvaImageWrapper({
            size,
            mobileSize,
            onSearchPopup,
          }),
          imageWrapperClassName
        )}
      >
        <AnimatePresence initial={false}>
          {images?.length ? (
            <>
              <MotionLazyImage
                key={activeIndex}
                className={twMergeExt(
                  'object-contain mix-blend-multiply'
                )}
                wrapClassName={
                  isSale
                    ? 'bg-cYellowCardOnGrey'
                    : isBgGray
                      ? 'bg-cLightGrey'
                      : ''
                }
                src={images[activeIndex]}
                alt={''}
                fill
                onLoad={() =>
                  setIsImageLoaded(true)
                }
              />
              {!isImageLoaded ? (
                isBgGray ? (
                  <EmptyIconLight className="relative z-10" />
                ) : (
                  <EmptyIcon className="relative z-10" />
                )
              ) : null}
            </>
          ) : isBgGray ? (
            <>
              <EmptyIconLight />
            </>
          ) : (
            <EmptyIcon />
          )}
        </AnimatePresence>
      </div>

      <div
        className={cvaHoverAreas({ size })}
        onMouseLeave={() => setActiveIndex(0)}
      >
        {new Array(images.length ?? 0)
          .fill(null)
          .map((_, index) => (
            <div
              key={index}
              className={cvaHoverArea()}
              onMouseEnter={() =>
                setActiveIndex(index)
              }
            />
          ))}
      </div>
    </div>
  );
};

const MotionLazyImage = motion(LazyImage);

const cvaRoot = cva(
  [
    'ProductPreviewCardImages-cvaRoot',
    'relative',
  ],
  {
    variants: {
      size: {
        default: 'sm:pt-5.2',
        large: 'sm:pt-0.9',
      },
      mobileSize: {
        default: 'pt-3.4',
        catalog: 'pt-3.6',
      },
      isUnavailable: {
        true: '!opacity-[0.48]',
        false: '',
      },
      onSearchPopup: {
        true: ['!pt-3.6 sm:!pt-4'],
      },
    },
  }
);

const cvaImageWrapper = cva(
  [
    'ProductPreviewCardImages-cvaImageWrapper',
    'relative',
    'mx-auto',
    'duration-400 transition-transform ease-[cubic-bezier(0.67,0,0,1)]',
  ],
  {
    variants: {
      size: {
        default: [
          'sm:w-[20rem] sm:h-[20rem]',
          'group-hover/ProductPreviewCard:scale-[108%] group-active/ProductPreviewCard:scale-[108%]',
        ],
        large: [
          'sm:w-[26.8rem] sm:h-[26.8rem]',
          'group-hover/ProductPreviewCard:scale-[106%] group-active/ProductPreviewCard:scale-[106%]',
        ],
      },
      mobileSize: {
        default: 'w-[13.2rem] h-[13.2rem]',
        catalog: 'w-[11.6rem] h-[11.6rem]',
      },
      onSearchPopup: {
        true: [
          '!w-[11.6rem] !h-[11.6rem] sm:!w-[14rem] sm:!h-[14rem]',
        ],
      },
    },
  }
);

const cvaHoverAreas = cva(
  [
    'ProductPreviewCardImages-cvaHoverAreas',
    'absolute top-0 left-0',
    'flex gap-0.8',
    'w-full',
    'opacity-0 invisible',
    'duration-400 transition-all ease-[cubic-bezier(0.67,0,0,1)]',
    'group-hover/ProductPreviewCard:visible group-hover/ProductPreviewCard:opacity-100',
    'group-active/ProductPreviewCard:visible group-active/ProductPreviewCard:opacity-100',
  ],
  {
    variants: {
      size: {
        default: 'h-[calc(100%+2.8rem)]',
        large: 'h-[calc(100%+1.6rem)]',
      },
    },
  }
);

const cvaHoverArea = cva([
  'ProductPreviewCardImages-cvaHoverArea',
  'relative',
  'flex-1',
]);

const cvaUnavailable = cva(
  [
    'ProductPreviewCardImages-cvaUnavailable',
    'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
    'py-1.2 px-1.6',
    'rounded-[1.6rem]',
    'flex items-center gap-0.6',
    'text-bodyMSemibold text-cBlack',
    'z-[9] whitespace-nowrap',
  ],
  {
    variants: {
      isBgGray: {
        true: 'bg-cWhite',
        false: 'bg-cMediumGreyOnWhite',
      },
    },
    defaultVariants: {
      isBgGray: false,
    },
  }
);
