'use client';
import { cva } from 'class-variance-authority';
import Image from 'next/image';
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactEventHandler,
  useState,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { twMergeExt } from '@/shared/utils/twMergeExt';
import { mergeRefs } from 'react-merge-refs';

interface Props
  extends ComponentPropsWithoutRef<typeof Image> {
  wrapClassName?: string;
}

export const LazyImage = forwardRef<
  HTMLDivElement,
  Props
>(
  (
    {
      quality = 90,
      alt,
      src,
      fill,
      width,
      height,
      className,
      priority = false,
      wrapClassName,
      onLoad,
      ...props
    },
    ref
  ) => {
    const [isLoaded, setIsLoaded] =
      useState(false);

    const { ref: imageWrapRef, inView } =
      useInView({
        triggerOnce: true,
        threshold: [0.1, 0.1, 0.1, 0.1],
      });

    const onLoadHandler: ReactEventHandler<
      HTMLImageElement
    > = (event) => {
      setIsLoaded(true);
      onLoad?.(event);
    };

    return (
      <div
        ref={mergeRefs([ref, imageWrapRef])}
        className={twMergeExt(
          cvaImageWrap({
            type: fill ? 'absolute' : 'relative',
          }),
          wrapClassName
        )}
      >
        <Image
          quality={quality}
          priority={priority}
          className={twMergeExt(
            cvaImage({
              state:
                isLoaded && inView
                  ? 'loaded'
                  : 'default',
            }),
            className
          )}
          src={src}
          width={width}
          height={height}
          fill={fill}
          alt={alt}
          onLoad={onLoadHandler}
          {...props}
        />
      </div>
    );
  }
);

LazyImage.displayName = 'LazyImage';

const cvaImageWrap = cva(
  [
    'overflow-hidden [backface-visibility:hidden]',
  ],
  {
    variants: {
      type: {
        relative: ['inline-block'],
        absolute: ['absolute inset-0'],
      },
    },
  }
);

const cvaImage = cva(
  [
    'transition-opacity duration-300 ease-in-out [backface-visibility:hidden]',
  ],
  {
    variants: {
      state: {
        default: ['opacity-0'],
        loaded: ['opacity-1'],
      },
    },
    defaultVariants: {
      state: 'default',
    },
  }
);
