import { API } from '../api';
import { axiosInstance } from '../axios';
import {
  FavoriteAddParams,
  IFavoriteData,
} from './types';

export const postFavoriteAdd = async (
  body: FavoriteAddParams
) => {
  try {
    const result =
      await axiosInstance.post<IFavoriteData>(
        API.postFavoriteAdd,
        body,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    return result.data;
  } catch (e: any) {
    console.error(e);
  }
};
