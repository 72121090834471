import { RefObject, useEffect } from 'react';

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (...args: any[]) => any,
  exceptions?: (RefObject<HTMLElement> | string)[]
) => {
  useEffect(() => {
    const listener = (event: any) => {
      for (
        let index = 0;
        index < (exceptions ?? []).length;
        index++
      ) {
        const exception =
          typeof exceptions![index] === 'string'
            ? document.querySelectorAll(
                `${exceptions![index]}`
              )
            : (exceptions![
                index
              ] as RefObject<HTMLElement>)!
                .current!;

        const exceptionElements = ((
          exception as NodeListOf<Element>
        )?.length
          ? Array.from(
              exception as NodeListOf<Element>
            )
          : [exception]
        )?.filter(
          Boolean
        ) as unknown as HTMLElement[];

        if (
          exceptionElements &&
          (exceptionElements.some(
            (i) => i === event.target
          ) ||
            exceptionElements.some((i) =>
              i.contains(event.target)
            ))
        ) {
          return;
        }
      }

      if (
        !ref.current ||
        ref.current.contains(event.target)
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener(
      'mousedown',
      listener
    );
    document.addEventListener(
      'touchstart',
      listener
    );

    return () => {
      document.removeEventListener(
        'mousedown',
        listener
      );
      document.removeEventListener(
        'touchstart',
        listener
      );
    };
  }, [ref, handler, exceptions]);
};
